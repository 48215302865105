import React from 'react';
import JoinUsDescription from './JoinUsDescription';

import Image from '../../../Components/Image';
import TESTIMAGEjpg from '../../../assets/JoinUs/joinUsImage.jpg';
import TESTIMAGEwebp from '../../../assets/JoinUs/joinUsImage.webp';

import styles from './JoinUs.module.css';

export default function JoinUs() {
  return (
    <div className={styles.container} id="joinwithus">
      <div className={`${styles.content}`}>
        <div className={styles.leftContainer}>
          <div className={`${styles.imageBox} center `}>
            <div className={styles.joinUsHeadingBox}>
              <span className={`headingText ${styles.joinUsHeading}`}>
                {' '}
                WORK WITH US
              </span>
            </div>
            <div className={styles.image}>
              <Image
                className={styles.image}
                webpImage={TESTIMAGEwebp}
                src={TESTIMAGEjpg}
              />
            </div>

            <JoinUsDescription />
          </div>
        </div>
      </div>
    </div>
  );
}
