import React from 'react';
import styles from './JoinUs.module.css';
import Descriptor from '../../../Components/Descriptor';

const description =
  'It is a unique chance to get yourself involved and start your career in a technology which is going to change rapidly over the decade.We are always in motion constantly improving and innovating in the fast-paced industry. Get a chance to work with a team of 100+ enlightened individuals and most importantly get a golden opportunity to interact with a group of people having years of experience in the industry and are now at the peak of their career.';
const JoinUsDescription = () => {
  return (
    <div className={styles.descriptionContainer}>
      <Descriptor
        descriptorAlignment={`${styles.joinUstextAlign}`}
        description={description}
        knowMoreText="READ MORE"
        haveIconBorder={true}
        isLight={true}
        maxDescriptionCharacters={195}
        disabled
      />
    </div>
  );
};

export default JoinUsDescription;
